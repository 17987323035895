import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPriceFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-price.generated';
import { FdrOfferRoomFragmentDoc } from 'fdr/schemas/fragment/room/fdr-room.generated';
import { FdrLinkFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-link.generated';
import { FdrCo2EmissionFragmentDoc } from 'fdr/schemas/fragment/co2-emission/fdr-co2-emission.generated';
import { FdrImageFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-image.generated';
import { FdrResizedFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-resized.generated';
export type FdrProductOfferItemFragment = {
  __typename?: 'FdrProductOffer';
  id: string;
  duration: number;
  hotelToAirportDistance?: number | null;
  transferIncluded: boolean;
  provider?: string | null;
  productCode?: string | null;
  travelType: Types.FdrProductTravelType;
  departureDate: any;
  returnDate: any;
  isInWishlist?: boolean | null;
  pricePerPerson: { __typename?: 'FdrPrice'; value: number; currency: string };
  rooms: Array<{
    __typename?: 'FdrProductOfferRoom';
    adults: number;
    childrenDobs?: Array<any> | null;
    mealDescription: string;
    roomDescription: string;
    bookingCode: string;
    mealType: string;
    roomType: string;
  }>;
  transferPrice?: {
    __typename?: 'FdrPrice';
    value: number;
    currency: string;
  } | null;
  webMeta: {
    __typename?: 'FdrSearchResultWebMeta';
    link: {
      __typename?: 'FdrLink';
      openMethod?: Types.FdrLinkOpenMethod | null;
      legacyUrl?: string | null;
      type: Types.FdrLinkType;
      url: string;
    };
  };
  co2Emission?: {
    __typename?: 'FdrCO2Emission';
    flightEmissionKgCO2e?: number | null;
    hotelEmissionKgCO2e?: number | null;
  } | null;
  product?:
    | {
        __typename: 'FdrCruise';
        giataCode?: number | null;
        name: string;
        fromTo?: string | null;
        mode?: string | null;
        id: string;
        hubData?: {
          __typename?: 'FdrHubProductData';
          taReviews: number;
          taRating: number;
        } | null;
        images?: Array<{
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        }> | null;
        disrupters?: Array<{
          __typename?: 'FdrDisrupter';
          text: string;
        }> | null;
        featureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            name: string;
            id: string;
            enabled: boolean;
          }>;
        }> | null;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
      }
    | {
        __typename: 'FdrHotel';
        giataCode?: number | null;
        name: string;
        rating: number;
        id: string;
        flags?: {
          __typename?: 'FdrHotelFlags';
          typeFlags?: {
            __typename?: 'FdrHotelTypeFlags';
            apartment: boolean;
          } | null;
        } | null;
        tripAdvisorReviews?: {
          __typename?: 'FdrTripAdvisorReviewsResponse';
          totalRating?: number | null;
          numReviews?: number | null;
        } | null;
        disrupters?: Array<{
          __typename?: 'FdrDisrupter';
          text: string;
        }> | null;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        featureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            name: string;
            id: string;
            enabled: boolean;
          }>;
        }> | null;
        resort: {
          __typename?: 'FdrResort';
          name: string;
          id: string;
          destination: {
            __typename?: 'FdrDestination';
            name: string;
            id: string;
            country: { __typename?: 'FdrCountry'; name: string; id: string };
          };
        };
      }
    | {
        __typename: 'FdrRoundTrip';
        giataCode?: number | null;
        name: string;
        fromTo?: string | null;
        mode?: string | null;
        id: string;
        hubData?: {
          __typename?: 'FdrHubProductData';
          taReviews: number;
          taRating: number;
        } | null;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        disrupters?: Array<{
          __typename?: 'FdrDisrupter';
          text: string;
        }> | null;
        featureGroups?: Array<{
          __typename?: 'FdrProductFeatureGroup';
          id: string;
          name: string;
          features: Array<{
            __typename?: 'FdrProductFeature';
            name: string;
            id: string;
            enabled: boolean;
          }>;
        }> | null;
        destination: {
          __typename?: 'FdrDestination';
          name: string;
          id: string;
          country: { __typename?: 'FdrCountry'; name: string; id: string };
        };
      }
    | { __typename: 'FdrAdventureTravel' }
    | null;
};

export type FdrProductRecommenderRoundTripFragment = {
  __typename?: 'FdrRoundTrip';
  giataCode?: number | null;
  name: string;
  fromTo?: string | null;
  mode?: string | null;
  id: string;
  hubData?: {
    __typename?: 'FdrHubProductData';
    taReviews: number;
    taRating: number;
  } | null;
  image?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  disrupters?: Array<{ __typename?: 'FdrDisrupter'; text: string }> | null;
  featureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      name: string;
      id: string;
      enabled: boolean;
    }>;
  }> | null;
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string; id: string };
  };
};

export type FdrProductRecommenderHotelFragment = {
  __typename?: 'FdrHotel';
  giataCode?: number | null;
  name: string;
  rating: number;
  id: string;
  flags?: {
    __typename?: 'FdrHotelFlags';
    typeFlags?: { __typename?: 'FdrHotelTypeFlags'; apartment: boolean } | null;
  } | null;
  tripAdvisorReviews?: {
    __typename?: 'FdrTripAdvisorReviewsResponse';
    totalRating?: number | null;
    numReviews?: number | null;
  } | null;
  disrupters?: Array<{ __typename?: 'FdrDisrupter'; text: string }> | null;
  image?: {
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  } | null;
  featureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      name: string;
      id: string;
      enabled: boolean;
    }>;
  }> | null;
  resort: {
    __typename?: 'FdrResort';
    name: string;
    id: string;
    destination: {
      __typename?: 'FdrDestination';
      name: string;
      id: string;
      country: { __typename?: 'FdrCountry'; name: string; id: string };
    };
  };
};

export type FdrProductRecommenderCruiseFragment = {
  __typename?: 'FdrCruise';
  giataCode?: number | null;
  name: string;
  fromTo?: string | null;
  mode?: string | null;
  id: string;
  hubData?: {
    __typename?: 'FdrHubProductData';
    taReviews: number;
    taRating: number;
  } | null;
  images?: Array<{
    __typename?: 'FdrImage';
    alt: string;
    description?: string | null;
    copyright?: string | null;
    rawUrl: string;
    desaturated?: boolean | null;
    resized: Array<{
      __typename?: 'FdrResizedImage';
      id: string;
      link: {
        __typename?: 'FdrLink';
        openMethod?: Types.FdrLinkOpenMethod | null;
        legacyUrl?: string | null;
        type: Types.FdrLinkType;
        url: string;
      };
    }>;
  }> | null;
  disrupters?: Array<{ __typename?: 'FdrDisrupter'; text: string }> | null;
  featureGroups?: Array<{
    __typename?: 'FdrProductFeatureGroup';
    id: string;
    name: string;
    features: Array<{
      __typename?: 'FdrProductFeature';
      name: string;
      id: string;
      enabled: boolean;
    }>;
  }> | null;
  destination: {
    __typename?: 'FdrDestination';
    name: string;
    id: string;
    country: { __typename?: 'FdrCountry'; name: string; id: string };
  };
};

export const FdrProductRecommenderHotelFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommenderHotel' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrHotel' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'giataCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'flags' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'typeFlags' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'apartment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tripAdvisorReviews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'page' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'perPage' },
                            value: { kind: 'IntValue', value: '1' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalRating' } },
                { kind: 'Field', name: { kind: 'Name', value: 'numReviews' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'image' },
            name: { kind: 'Name', value: 'hero' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productImageCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'featureGroups' },
            name: { kind: 'Name', value: 'summaryFeatureGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resort' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'destination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductRecommenderRoundTripFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommenderRoundTrip' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrRoundTrip' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'giataCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'taReviews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taRating' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'image' },
            name: { kind: 'Name', value: 'hero' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productImageCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductRecommenderCruiseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductRecommenderCruise' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrCruise' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'id' },
            name: { kind: 'Name', value: 'publicId' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'giataCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fromTo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mode' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hubData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'taReviews' } },
                { kind: 'Field', name: { kind: 'Name', value: 'taRating' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImage' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resized' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'criteria' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productImageCriteria' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrResized' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disrupters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'featureGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'features' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'enabled' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'destination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'id' },
                  name: { kind: 'Name', value: 'publicId' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'country' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'id' },
                        name: { kind: 'Name', value: 'publicId' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrProductOfferItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrProductOfferItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrProductOffer' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'duration' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pricePerPerson' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rooms' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrOfferRoom' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hotelToAirportDistance' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'transferIncluded' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'transferPrice' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPrice' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'webMeta' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'link' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrLink' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'provider' } },
          { kind: 'Field', name: { kind: 'Name', value: 'productCode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'travelType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'departureDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'returnDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isInWishlist' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'co2Emission' },
            directives: [
              {
                kind: 'Directive',
                name: { kind: 'Name', value: 'include' },
                arguments: [
                  {
                    kind: 'Argument',
                    name: { kind: 'Name', value: 'if' },
                    value: {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'withCO2Emission' },
                    },
                  },
                ],
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrCO2Emission' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrHotel' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderHotel',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrRoundTrip' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderRoundTrip',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrCruise' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrProductRecommenderCruise',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPriceFragmentDoc.definitions,
    ...FdrOfferRoomFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
    ...FdrCo2EmissionFragmentDoc.definitions,
    ...FdrProductRecommenderHotelFragmentDoc.definitions,
    ...FdrProductRecommenderRoundTripFragmentDoc.definitions,
    ...FdrProductRecommenderCruiseFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
