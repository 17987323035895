import { ApolloQueryResult } from '@apollo/client';
import { getNotNullObjectFieldFilter } from 'hp-mf/fdr/lib/filter/get-not-null-object-field-filter';
import {
  TFdrRecommendationFragmentUnion,
  TRecommendersQueryUnion,
} from './component-request.types';

export const createInactiveProductFilter = <
  TQuery extends object,
  TItem extends object
>(
  fieldAccessor: (item: TItem) => object | null
) => {
  return (response: ApolloQueryResult<TQuery>) => {
    const components = (response.data as TRecommendersQueryUnion).fdrComponents
      .components[0] as TFdrRecommendationFragmentUnion;
    const itemsPage =
      components[
        `${
          components.__typename === 'FdrFlightOfferRecommender'
            ? 'flightItemsPage'
            : 'itemsPage'
        }`
      ];
    itemsPage.items = (itemsPage.items ?? []).filter(
      getNotNullObjectFieldFilter(fieldAccessor)
    );
    return response;
  };
};
