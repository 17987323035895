import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrMarketingRecommenderFragmentDoc } from 'fdr/schemas/fragment/recommenders/marketing/fdr-marketing-recommendation.generated';
import { FdrGeoOfferRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/geo/fdr-geo-offer-recommendation.generated';
import { FdrPaginationFragmentDoc } from 'fdr/schemas/fragment/regular/fdr-pagination.generated';
import { FdrThemeOfferRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/theme/fdr-theme-offer-recommendation.generated';
import { FdrProductOfferRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/product/fdr-product-offer-recommendation.generated';
import { FdrBlogArticleRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/blog-article/fdr-blog-article-recommendation.generated';
import { FdrFlightOfferRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/flight/fdr-flight-offer-recommendation.generated';
import { FdrSmartSeerRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/smart-seer/fdr-smart-seer-recommendation.generated';
import { FdrFlightRecommendationFragmentDoc } from 'fdr/schemas/fragment/recommenders/flight/fdr-flight-recommendation.generated';
import { FdrGeoOfferButtonPlaceholderFragmentDoc } from 'fdr/schemas/fragment/page-components-placeholders/fdr-geo-offer-button-placeholder.generated';
import { FdrGeoMapPlaceholderFragmentDoc } from 'fdr/schemas/fragment/page-components-placeholders/fdr-geo-map-placeholder.generated';
import { FdrGeoClimatePlaceholderFragmentDoc } from 'fdr/schemas/fragment/page-components-placeholders/fdr-geo-climate-placeholder.generated';
import { FdrGeoChildrenPlaceholderFragmentDoc } from 'fdr/schemas/fragment/page-components-placeholders/fdr-geo-children-placeholder.generated';
import { FdrGeoGalleryPlaceholderFragmentDoc } from 'fdr/schemas/fragment/page-components-placeholders/fdr-geo-gallery-placeholder.generated';
import { FdrMarketingTeaserFragmentDoc } from 'fdr/schemas/fragment/recommenders/marketing/fdr-marketing-teaser.generated';
import { FdrNewsletterSignupFragmentDoc } from 'fdr/schemas/fragment/newsletter-signup/fdr-newsletter-signup.generated';
import {
  FdrMarkdownTextFragmentDoc,
  FdrRawTextFragmentDoc,
} from 'fdr/schemas/fragment/regular/fdr-text.generated';
import { FdrImageTextFragmentDoc } from 'fdr/schemas/fragment/image-text/fdr-image-text.generated';
import { FdrFaqFragmentDoc } from 'fdr/schemas/fragment/faq/fdr-faq.generated';
import { FdrAccordionComponentFragmentDoc } from 'fdr/schemas/fragment/accordion-component/fdr-accordion-component.generated';
import { FdrMediaGalleryFragmentDoc } from 'fdr/schemas/fragment/media-gallery/fdr-media-gallery.generated';
import { FdrSrlButtonFragmentDoc } from './fdr-srl-button.generated';
import { FdrProductOffersMapFragmentDoc } from './fdr-product-offers-map.generated';
export type FdrPageComponentsFragment = {
  __typename?: 'FdrPageComponents';
  components?: Array<
    | {
        __typename: 'FdrSmartSeerRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        title?: string | null;
        text?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        itemsPage: {
          __typename?: 'FdrSmartSeerRecommenderSearchResult';
          placementId: string;
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<{
            __typename?: 'FdrProductOffer';
            id: string;
            duration: number;
            hotelToAirportDistance?: number | null;
            transferIncluded: boolean;
            provider?: string | null;
            productCode?: string | null;
            travelType: Types.FdrProductTravelType;
            departureDate: any;
            returnDate: any;
            isInWishlist?: boolean | null;
            pricePerPerson: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            rooms: Array<{
              __typename?: 'FdrProductOfferRoom';
              adults: number;
              childrenDobs?: Array<any> | null;
              mealDescription: string;
              roomDescription: string;
              bookingCode: string;
              mealType: string;
              roomType: string;
            }>;
            transferPrice?: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            } | null;
            webMeta: {
              __typename?: 'FdrSearchResultWebMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
            co2Emission?: {
              __typename?: 'FdrCO2Emission';
              flightEmissionKgCO2e?: number | null;
              hotelEmissionKgCO2e?: number | null;
            } | null;
            product?:
              | {
                  __typename: 'FdrCruise';
                  giataCode?: number | null;
                  name: string;
                  fromTo?: string | null;
                  mode?: string | null;
                  id: string;
                  hubData?: {
                    __typename?: 'FdrHubProductData';
                    taReviews: number;
                    taRating: number;
                  } | null;
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                  }> | null;
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      name: string;
                      id: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                    };
                  };
                }
              | {
                  __typename: 'FdrHotel';
                  giataCode?: number | null;
                  name: string;
                  rating: number;
                  id: string;
                  flags?: {
                    __typename?: 'FdrHotelFlags';
                    typeFlags?: {
                      __typename?: 'FdrHotelTypeFlags';
                      apartment: boolean;
                    } | null;
                  } | null;
                  tripAdvisorReviews?: {
                    __typename?: 'FdrTripAdvisorReviewsResponse';
                    totalRating?: number | null;
                    numReviews?: number | null;
                  } | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                  }> | null;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      name: string;
                      id: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  resort: {
                    __typename?: 'FdrResort';
                    name: string;
                    id: string;
                    destination: {
                      __typename?: 'FdrDestination';
                      name: string;
                      id: string;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                      };
                    };
                  };
                }
              | {
                  __typename: 'FdrRoundTrip';
                  giataCode?: number | null;
                  name: string;
                  fromTo?: string | null;
                  mode?: string | null;
                  id: string;
                  hubData?: {
                    __typename?: 'FdrHubProductData';
                    taReviews: number;
                    taRating: number;
                  } | null;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                  }> | null;
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      name: string;
                      id: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                    };
                  };
                }
              | { __typename: 'FdrAdventureTravel' }
              | null;
          }> | null;
          tracking: {
            __typename?: 'FdrSmartSeerRecommenderTracking';
            giataCodes?: Array<string> | null;
            modelId?: string | null;
            pToken?: string | null;
            status: Types.FdrSmartSeerRecommenderStatus;
            strategy?: string | null;
          };
        };
      }
    | {
        __typename: 'FdrRssBlogArticleRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        text?: string | null;
        fgColor?: string | null;
        bgColor?: string | null;
        title?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        itemsPage: {
          __typename?: 'FdrRssBlogArticlePage';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<{
            __typename?: 'FdrRssBlogArticle';
            text: string;
            author?: string | null;
            date: string;
            title: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          }> | null;
        };
      }
    | {
        __typename: 'FdrAccordion';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrAccordionComponent';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        bottomBackgroundColor?: string | null;
        bottomFontColor?: string | null;
        bottomMainText?: string | null;
        bottomMainTitle?: string | null;
        topBackgroundColor?: string | null;
        topFontColor?: string | null;
        topMainText?: string | null;
        topMainTitle?: string | null;
        accordionComponents?: {
          __typename?: 'FdrAccordionComponents';
          components?: Array<
            | { __typename: 'FdrSmartSeerRecommender' }
            | { __typename: 'FdrRssBlogArticleRecommender' }
            | { __typename: 'FdrAccordion' }
            | { __typename: 'FdrAccordionComponent' }
            | { __typename: 'FdrAgencyContactModule' }
            | { __typename: 'FdrAgencyRecommender' }
            | { __typename: 'FdrBenefitsComponent' }
            | { __typename: 'FdrBlogArticleRecommender' }
            | { __typename: 'FdrBrandsComponent' }
            | { __typename: 'FdrContactModule' }
            | { __typename: 'FdrEmployeesComponent' }
            | {
                __typename: 'FdrFaq';
                bgColor?: string | null;
                fgColor?: string | null;
                answer: string;
                question: string;
              }
            | { __typename: 'FdrFlightOfferRecommender' }
            | { __typename: 'FdrFlightRecommender' }
            | { __typename: 'FdrGeoChildrenPlaceholder' }
            | { __typename: 'FdrGeoClimatePlaceholder' }
            | { __typename: 'FdrGeoGalleryPlaceholder' }
            | { __typename: 'FdrGeoLeadTextPlaceholder' }
            | { __typename: 'FdrGeoLongTextPlaceholder' }
            | { __typename: 'FdrGeoMapPlaceholder' }
            | { __typename: 'FdrGeoOfferButtonPlaceholder' }
            | { __typename: 'FdrGeoOfferRecommender' }
            | { __typename: 'FdrGeoRecommender' }
            | { __typename: 'FdrGeoRecommenderPlaceholder' }
            | { __typename: 'FdrHeroMediaGallery' }
            | { __typename: 'FdrImageText' }
            | { __typename: 'FdrLinkList' }
            | { __typename: 'FdrMarkdownText' }
            | { __typename: 'FdrMarketingRecommender' }
            | { __typename: 'FdrMarketingTeaser' }
            | { __typename: 'FdrMediaGallery' }
            | { __typename: 'FdrNewsArticlesRecommender' }
            | { __typename: 'FdrNewsletterSignup' }
            | { __typename: 'FdrProductOfferRecommender' }
            | { __typename: 'FdrProductOffersMap' }
            | { __typename: 'FdrProductRecommender' }
            | { __typename: 'FdrRawText' }
            | { __typename: 'FdrSrlButton' }
            | { __typename: 'FdrThemeGeoRecommender' }
            | { __typename: 'FdrThemeOfferRecommender' }
            | { __typename: 'FdrThemeRecommender' }
            | { __typename: 'FdrUspBoxesComponent' }
          > | null;
        } | null;
      }
    | {
        __typename: 'FdrAgencyContactModule';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrAgencyRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrBenefitsComponent';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrBlogArticleRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrBrandsComponent';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrContactModule';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrEmployeesComponent';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrFaq';
        inPageNavigable: boolean;
        anchorId?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        answer: string;
        question: string;
      }
    | {
        __typename: 'FdrFlightOfferRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        title?: string | null;
        text?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        flightItemsPage: {
          __typename?: 'FdrFlightOfferRecommenderSearchResult';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<{
            __typename?: 'FdrFlightRecommenderOfferItem';
            amount: number;
            item?: {
              __typename?: 'FdrFlightOfferRecommenderItem';
              title?: string | null;
              recommenderId: string;
              itemIndex: number;
              media?:
                | { __typename?: 'FdrAudio' }
                | { __typename?: 'FdrExternalMedia' }
                | {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }
                | { __typename?: 'FdrPdf' }
                | { __typename?: 'FdrVideo' }
                | null;
            } | null;
            offer: {
              __typename?: 'FdrFlightOffer';
              id: string;
              webMeta: {
                __typename?: 'FdrFlightSearchResultWebMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              };
              forwardFlight: {
                __typename?: 'FdrFlight';
                duration: number;
                overnight: boolean;
                baggageInfo?: Array<string> | null;
                segments: Array<{
                  __typename?: 'FdrFlightSegment';
                  flightNumber: string;
                  flightClass: Types.FdrFlightClass;
                  duration: number;
                  arrivalDate: any;
                  arrivalTime: any;
                  baggageIncluded: boolean;
                  baggageInfo?: Array<string> | null;
                  departureDate: any;
                  departureTime: any;
                  airportWaiting?: number | null;
                  airline: {
                    __typename?: 'FdrAirline';
                    iata: string;
                    name: string;
                  };
                  arrivalAirport: {
                    __typename?: 'FdrAirport';
                    name: string;
                    isGroup: boolean;
                    iata: string;
                    codes?: Array<string> | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      longitude: number;
                      latitude: number;
                    } | null;
                  };
                  departureAirport: {
                    __typename?: 'FdrAirport';
                    name: string;
                    isGroup: boolean;
                    iata: string;
                    codes?: Array<string> | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      longitude: number;
                      latitude: number;
                    } | null;
                  };
                }>;
              };
              returnFlight?: {
                __typename?: 'FdrFlight';
                duration: number;
                overnight: boolean;
                baggageInfo?: Array<string> | null;
                segments: Array<{
                  __typename?: 'FdrFlightSegment';
                  flightNumber: string;
                  flightClass: Types.FdrFlightClass;
                  duration: number;
                  arrivalDate: any;
                  arrivalTime: any;
                  baggageIncluded: boolean;
                  baggageInfo?: Array<string> | null;
                  departureDate: any;
                  departureTime: any;
                  airportWaiting?: number | null;
                  airline: {
                    __typename?: 'FdrAirline';
                    iata: string;
                    name: string;
                  };
                  arrivalAirport: {
                    __typename?: 'FdrAirport';
                    name: string;
                    isGroup: boolean;
                    iata: string;
                    codes?: Array<string> | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      longitude: number;
                      latitude: number;
                    } | null;
                  };
                  departureAirport: {
                    __typename?: 'FdrAirport';
                    name: string;
                    isGroup: boolean;
                    iata: string;
                    codes?: Array<string> | null;
                    coordinates?: {
                      __typename?: 'FdrCoordinates';
                      longitude: number;
                      latitude: number;
                    } | null;
                  };
                }>;
              } | null;
              perPersonPrice: {
                __typename?: 'FdrPrice';
                value: number;
                currency: string;
              };
            };
          } | null> | null;
        };
      }
    | {
        __typename: 'FdrFlightRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        title?: string | null;
        text?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        flightRecommenderItems: Array<{
          __typename?: 'FdrFlightRecommenderItem';
          fromPrice: number;
          subtitle: string;
          title: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
          media?:
            | {
                __typename?: 'FdrAudio';
                id?: string | null;
                title?: string | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }
            | {
                __typename?: 'FdrExternalMedia';
                id?: string | null;
                title?: string | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }
            | {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resizedPreview: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
                resizedImage: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              }
            | {
                __typename?: 'FdrPdf';
                id?: string | null;
                caption: string;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }
            | {
                __typename?: 'FdrVideo';
                id?: string | null;
                title?: string | null;
                copyright?: string | null;
                width?: number | null;
                height?: number | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }
            | null;
        }>;
      }
    | {
        __typename: 'FdrGeoChildrenPlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
        anchorLabel?: string | null;
        bgColor?: string | null;
        id: string;
        text?: string | null;
      }
    | {
        __typename: 'FdrGeoClimatePlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
        anchorLabel?: string | null;
        bgColor?: string | null;
        id: string;
        text?: string | null;
      }
    | {
        __typename: 'FdrGeoGalleryPlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
        anchorLabel?: string | null;
        bgColor?: string | null;
        id: string;
        text?: string | null;
      }
    | {
        __typename: 'FdrGeoLeadTextPlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrGeoLongTextPlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrGeoMapPlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
        anchorLabel?: string | null;
        bgColor?: string | null;
        id: string;
        text?: string | null;
      }
    | {
        __typename: 'FdrGeoOfferButtonPlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
        anchorLabel?: string | null;
        bgColor?: string | null;
        id: string;
      }
    | {
        __typename: 'FdrGeoOfferRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        text?: string | null;
        title?: string | null;
        titleFgColor?: string | null;
        id: string;
        fgColor?: string | null;
        displayType: string;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        itemsPage: {
          __typename?: 'FdrGeoOfferRecommenderSearchResult';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<{
            __typename?: 'FdrGeoOffer';
            productCount: number;
            webMeta: {
              __typename?: 'FdrSearchResultWebMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
            geo?:
              | {
                  __typename?: 'FdrDestination';
                  flightTime?: number | null;
                  mapZoom: number;
                  id: string;
                  title: string;
                  country: { __typename?: 'FdrCountry'; name: string };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    latitude: number;
                    longitude: number;
                  } | null;
                }
              | {
                  __typename?: 'FdrCountry';
                  flightTime?: number | null;
                  mapZoom: number;
                  id: string;
                  title: string;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    latitude: number;
                    longitude: number;
                  } | null;
                }
              | {
                  __typename?: 'FdrContinent';
                  mapZoom: number;
                  id: string;
                  title: string;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    latitude: number;
                    longitude: number;
                  } | null;
                }
              | {
                  __typename?: 'FdrCountryGroup';
                  mapZoom: number;
                  id: string;
                  title: string;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    latitude: number;
                    longitude: number;
                  } | null;
                }
              | {
                  __typename?: 'FdrRegion';
                  flightTime?: number | null;
                  mapZoom: number;
                  id: string;
                  title: string;
                  country: { __typename?: 'FdrCountry'; name: string };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    latitude: number;
                    longitude: number;
                  } | null;
                }
              | {
                  __typename?: 'FdrResort';
                  flightTime?: number | null;
                  mapZoom: number;
                  id: string;
                  title: string;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  coordinates?: {
                    __typename?: 'FdrCoordinates';
                    latitude: number;
                    longitude: number;
                  } | null;
                }
              | null;
            topOffer: {
              __typename?: 'FdrProductOffer';
              duration: number;
              averageTemperature?: number | null;
              pricePerPerson: {
                __typename?: 'FdrPrice';
                value: number;
                currency: string;
              };
              rooms: Array<{
                __typename?: 'FdrProductOfferRoom';
                adults: number;
                childrenDobs?: Array<any> | null;
              }>;
              product?:
                | {
                    __typename?: 'FdrCruise';
                    id: string;
                    disrupters?: Array<{
                      __typename?: 'FdrDisrupter';
                      text: string;
                    }> | null;
                  }
                | {
                    __typename?: 'FdrHotel';
                    id: string;
                    disrupters?: Array<{
                      __typename?: 'FdrDisrupter';
                      text: string;
                    }> | null;
                  }
                | {
                    __typename?: 'FdrRoundTrip';
                    id: string;
                    disrupters?: Array<{
                      __typename?: 'FdrDisrupter';
                      text: string;
                    }> | null;
                  }
                | {
                    __typename?: 'FdrAdventureTravel';
                    id: string;
                    disrupters?: Array<{
                      __typename?: 'FdrDisrupter';
                      text: string;
                    }> | null;
                  }
                | null;
            };
          }> | null;
        };
      }
    | {
        __typename: 'FdrGeoRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrGeoRecommenderPlaceholder';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrHeroMediaGallery';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrImageText';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        text?: string | null;
        placement?: string | null;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          imageTextResized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      }
    | {
        __typename: 'FdrLinkList';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrMarkdownText';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        bgColor?: string | null;
        fgColor?: string | null;
        text?: string | null;
        topPadding?: string | null;
        bottomPadding?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      }
    | {
        __typename: 'FdrMarketingRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        text?: string | null;
        fgColor?: string | null;
        titleFgColor?: string | null;
        bgColor?: string | null;
        title?: string | null;
        displayType: string;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        items: Array<{
          __typename?: 'FdrMarketingRecommenderItem';
          title?: string | null;
          subtitle: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
          media?:
            | {
                __typename?: 'FdrAudio';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrExternalMedia';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resizedPreview: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
                resizedImage: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              }
            | {
                __typename?: 'FdrPdf';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | {
                __typename?: 'FdrVideo';
                resourceLink: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
                preview?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                image?: {
                  __typename?: 'FdrImage';
                  alt: string;
                  description?: string | null;
                  copyright?: string | null;
                  rawUrl: string;
                  desaturated?: boolean | null;
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
              }
            | null;
        }>;
      }
    | {
        __typename: 'FdrMarketingTeaser';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        backgroundColorAlignment?: string | null;
        backgroundColor: string;
        backgroundTheme: string;
        bottomPadding?: string | null;
        topPadding?: string | null;
        buttonTitle: string;
        mainText: string;
        title?: string | null;
        buttonLink: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
        teaserImage: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        };
        logoImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      }
    | {
        __typename: 'FdrMediaGallery';
        inPageNavigable: boolean;
        anchorId?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        media: Array<
          | {
              __typename?: 'FdrAudio';
              id?: string | null;
              title?: string | null;
              thumbnail?: {
                __typename: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              image?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
          | {
              __typename?: 'FdrExternalMedia';
              id?: string | null;
              title?: string | null;
              thumbnail?: {
                __typename: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              image?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
          | {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              desaturated?: boolean | null;
              thumbnailResized: Array<{
                __typename: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            }
          | {
              __typename?: 'FdrPdf';
              id?: string | null;
              caption: string;
              thumbnail?: {
                __typename: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              image?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
          | {
              __typename?: 'FdrVideo';
              id?: string | null;
              title?: string | null;
              copyright?: string | null;
              width?: number | null;
              height?: number | null;
              thumbnail?: {
                __typename: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              image?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              resourceLink: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }
        >;
      }
    | {
        __typename: 'FdrNewsArticlesRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrNewsletterSignup';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        bgColor?: string | null;
        buttonText: string;
        disclaimerText: string;
        mainText: string;
        successMessageText: string;
        successMessageTitle: string;
        title?: string | null;
        image?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        successMessageTextIcon?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      }
    | {
        __typename: 'FdrProductOfferRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        title?: string | null;
        text?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        itemsPage: {
          __typename?: 'FdrProductSearchResult';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<{
            __typename?: 'FdrProductOffer';
            id: string;
            duration: number;
            hotelToAirportDistance?: number | null;
            transferIncluded: boolean;
            provider?: string | null;
            productCode?: string | null;
            travelType: Types.FdrProductTravelType;
            departureDate: any;
            returnDate: any;
            isInWishlist?: boolean | null;
            pricePerPerson: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            };
            rooms: Array<{
              __typename?: 'FdrProductOfferRoom';
              adults: number;
              childrenDobs?: Array<any> | null;
              mealDescription: string;
              roomDescription: string;
              bookingCode: string;
              mealType: string;
              roomType: string;
            }>;
            transferPrice?: {
              __typename?: 'FdrPrice';
              value: number;
              currency: string;
            } | null;
            webMeta: {
              __typename?: 'FdrSearchResultWebMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
            co2Emission?: {
              __typename?: 'FdrCO2Emission';
              flightEmissionKgCO2e?: number | null;
              hotelEmissionKgCO2e?: number | null;
            } | null;
            product?:
              | {
                  __typename: 'FdrCruise';
                  giataCode?: number | null;
                  name: string;
                  fromTo?: string | null;
                  mode?: string | null;
                  id: string;
                  hubData?: {
                    __typename?: 'FdrHubProductData';
                    taReviews: number;
                    taRating: number;
                  } | null;
                  images?: Array<{
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  }> | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                  }> | null;
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      name: string;
                      id: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                    };
                  };
                }
              | {
                  __typename: 'FdrHotel';
                  giataCode?: number | null;
                  name: string;
                  rating: number;
                  id: string;
                  flags?: {
                    __typename?: 'FdrHotelFlags';
                    typeFlags?: {
                      __typename?: 'FdrHotelTypeFlags';
                      apartment: boolean;
                    } | null;
                  } | null;
                  tripAdvisorReviews?: {
                    __typename?: 'FdrTripAdvisorReviewsResponse';
                    totalRating?: number | null;
                    numReviews?: number | null;
                  } | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                  }> | null;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      name: string;
                      id: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  resort: {
                    __typename?: 'FdrResort';
                    name: string;
                    id: string;
                    destination: {
                      __typename?: 'FdrDestination';
                      name: string;
                      id: string;
                      country: {
                        __typename?: 'FdrCountry';
                        name: string;
                        id: string;
                      };
                    };
                  };
                }
              | {
                  __typename: 'FdrRoundTrip';
                  giataCode?: number | null;
                  name: string;
                  fromTo?: string | null;
                  mode?: string | null;
                  id: string;
                  hubData?: {
                    __typename?: 'FdrHubProductData';
                    taReviews: number;
                    taRating: number;
                  } | null;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    desaturated?: boolean | null;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                  disrupters?: Array<{
                    __typename?: 'FdrDisrupter';
                    text: string;
                  }> | null;
                  featureGroups?: Array<{
                    __typename?: 'FdrProductFeatureGroup';
                    id: string;
                    name: string;
                    features: Array<{
                      __typename?: 'FdrProductFeature';
                      name: string;
                      id: string;
                      enabled: boolean;
                    }>;
                  }> | null;
                  destination: {
                    __typename?: 'FdrDestination';
                    name: string;
                    id: string;
                    country: {
                      __typename?: 'FdrCountry';
                      name: string;
                      id: string;
                    };
                  };
                }
              | { __typename: 'FdrAdventureTravel' }
              | null;
          }> | null;
        };
      }
    | {
        __typename: 'FdrProductOffersMap';
        inPageNavigable: boolean;
        anchorId?: string | null;
        anchorLabel?: string | null;
        id: string;
      }
    | {
        __typename: 'FdrProductRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrRawText';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        bgColor?: string | null;
        fgColor?: string | null;
        text?: string | null;
        topPadding?: string | null;
        bottomPadding?: string | null;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
      }
    | {
        __typename: 'FdrSrlButton';
        inPageNavigable: boolean;
        anchorId?: string | null;
        anchorLabel?: string | null;
        id: string;
        webMeta: {
          __typename?: 'FdrSearchResultWebMeta';
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        };
      }
    | {
        __typename: 'FdrThemeGeoRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrThemeOfferRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
        id: string;
        title?: string | null;
        text?: string | null;
        bgColor?: string | null;
        fgColor?: string | null;
        displayType: string;
        bgImage?: {
          __typename?: 'FdrImage';
          alt: string;
          description?: string | null;
          copyright?: string | null;
          rawUrl: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        itemsPage: {
          __typename?: 'FdrThemeOfferRecommenderSearchResult';
          pagination: {
            __typename?: 'FdrPagination';
            total: number;
            page: number;
            perPage: number;
          };
          items?: Array<{
            __typename?: 'FdrThemeOffer';
            theme: {
              __typename?: 'FdrTheme';
              id: string;
              title: string;
              image?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                desaturated?: boolean | null;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
            };
            offer: {
              __typename?: 'FdrProductOffer';
              duration: number;
              product?:
                | { __typename?: 'FdrCruise'; id: string }
                | { __typename?: 'FdrHotel'; id: string }
                | { __typename?: 'FdrRoundTrip'; id: string }
                | { __typename?: 'FdrAdventureTravel'; id: string }
                | null;
              pricePerPerson: {
                __typename?: 'FdrPrice';
                value: number;
                currency: string;
              };
              rooms: Array<{
                __typename?: 'FdrProductOfferRoom';
                childrenDobs?: Array<any> | null;
                adults: number;
              }>;
            };
            webMeta: {
              __typename?: 'FdrSearchResultWebMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            };
          }> | null;
        };
      }
    | {
        __typename: 'FdrThemeRecommender';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
    | {
        __typename: 'FdrUspBoxesComponent';
        inPageNavigable: boolean;
        anchorId?: string | null;
      }
  > | null;
  pagination: {
    __typename?: 'FdrPagination';
    total: number;
    page: number;
    perPage: number;
  };
};

export const FdrPageComponentsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrPageComponents' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrPageComponents' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'components' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'inPageNavigable' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'anchorId' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMarketingRecommender' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrGeoOfferRecommendation' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrThemeOfferRecommendation' },
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'fdrProductOfferRecommendation',
                  },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrBlogArticleRecommendation' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlightOfferRecommendation' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrSmartSeerRecommendation' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFlightRecommendation' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrGeoOfferButtonPlaceholder' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrGeoMapPlaceholder' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrGeoClimatePlaceholder' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrGeoChildrenPlaceholder' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrGeoGalleryPlaceholder' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'FdrMarketingTeaser' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrNewsletterSignup' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMarkdownText' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrRawText' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrImageText' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrFaq' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrAccordionComponent' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrMediaGallery' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrSrlButton' },
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrProductOffersMap' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pagination' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'fdrPagination' },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrMarketingRecommenderFragmentDoc.definitions,
    ...FdrGeoOfferRecommendationFragmentDoc.definitions,
    ...FdrThemeOfferRecommendationFragmentDoc.definitions,
    ...FdrProductOfferRecommendationFragmentDoc.definitions,
    ...FdrBlogArticleRecommendationFragmentDoc.definitions,
    ...FdrFlightOfferRecommendationFragmentDoc.definitions,
    ...FdrSmartSeerRecommendationFragmentDoc.definitions,
    ...FdrFlightRecommendationFragmentDoc.definitions,
    ...FdrGeoOfferButtonPlaceholderFragmentDoc.definitions,
    ...FdrGeoMapPlaceholderFragmentDoc.definitions,
    ...FdrGeoClimatePlaceholderFragmentDoc.definitions,
    ...FdrGeoChildrenPlaceholderFragmentDoc.definitions,
    ...FdrGeoGalleryPlaceholderFragmentDoc.definitions,
    ...FdrMarketingTeaserFragmentDoc.definitions,
    ...FdrNewsletterSignupFragmentDoc.definitions,
    ...FdrMarkdownTextFragmentDoc.definitions,
    ...FdrRawTextFragmentDoc.definitions,
    ...FdrImageTextFragmentDoc.definitions,
    ...FdrFaqFragmentDoc.definitions,
    ...FdrAccordionComponentFragmentDoc.definitions,
    ...FdrMediaGalleryFragmentDoc.definitions,
    ...FdrSrlButtonFragmentDoc.definitions,
    ...FdrProductOffersMapFragmentDoc.definitions,
    ...FdrPaginationFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
